<template>
  <div id="app">
    <img alt="Vue logo" src="./assets/logo.png">
    <h1>Redeem Linguicity Promo Codes</h1>
    <login-button v-if="!$route.hash.includes('id_token')" />
    <code-form v-if="$route.hash.includes('id_token')" />
  </div>
</template>

<script>
import LoginButton from './components/LoginButton.vue'
import CodeForm from './components/CodeForm.vue'

export default {
  name: 'App',
  components: {
    LoginButton,
    CodeForm
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
