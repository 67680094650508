<template>
  <b-container>
    <b-card>
      <b-alert variant="success" v-model="success">Code Redeemed!</b-alert>
      <b-alert variant="danger" v-model="codeerror"
        >This code is invalid or has already been redeemed</b-alert
      >
      <b-form @submit="onSubmit">
        <b-form-group
          id="input-group-1"
          label="Promo Code:"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="form.code"
            type="text"
            placeholder="Promo code"
            required
          ></b-form-input>
        </b-form-group>
        <b-button type="submit" variant="primary" class="mt-1">Redeem</b-button>
      </b-form>
    </b-card>
  </b-container>
</template>
<script>
export default {
  data: function () {
    return {
      success: false,
      codeerror: false,
      form: {
        code: "",
      },
    };
  },

  created: function () {
    //window.alert(this.$route.hash.replace("#", "").split("&")[0].split('=')[1]);
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      //alert(JSON.stringify(this.form));
      let comp = this;
      this.ajaxPost("https://api-ling.p2d-dev.com/codes/redeem", {code : comp.form.code}, function() {
          //window.alert("A");
          comp.success = true;
          comp.codeerror = false;
      }, function() {
          //window.alert("B");
          comp.success = false;
          comp.codeerror = true;
      }, this.$route.hash.replace("#", "").split("&")[0].split('=')[1]);
    },
    ajaxPost(url, data, success, error, token = null) {
      var request = new XMLHttpRequest();
      request.open("POST", url, true);

      request.onload = function () {
        if (this.status >= 200 && this.status < 400) {
          success(JSON.parse(this.response));
        } else {
          // We reached our target server, but it returned an error
          error(this);
        }
      };

      request.onerror = function () {
        // There was a connection error of some sort
        error();
      };

      request.setRequestHeader("Content-Type", "application/json");

      if (token) request.setRequestHeader("Authorization", "Bearer " + token);

      request.send(JSON.stringify(data));
    },
  },
};
</script>
